// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cgv-tsx": () => import("./../../../src/pages/cgv.tsx" /* webpackChunkName: "component---src-pages-cgv-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mentions-legales-tsx": () => import("./../../../src/pages/mentions-legales.tsx" /* webpackChunkName: "component---src-pages-mentions-legales-tsx" */),
  "component---src-pages-politique-de-confidentialite-tsx": () => import("./../../../src/pages/politique-de-confidentialite.tsx" /* webpackChunkName: "component---src-pages-politique-de-confidentialite-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

